/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import { Page } from 'Component/Header/Header.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import { scrollToTop } from 'Util/Browser';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

/** @namespace Satisfly/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    _getIsInfiniteLoaderEnabled() { // disable infinite scroll on mobile
        return false;
    }

    componentDidMount() {
        const { pages, isPreventRequest } = this.props;
        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            this.setState({ pagesCount: pagesLength });
        }

        // Is true when category is changed. This check prevents making new requests when navigating back to PLP from PDP
        if (!isPreventRequest) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    componentDidUpdate(prevProps) {
        const {
            sort,
            search,
            filter,
            pages,
            device,
            isPlp,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();
        const isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(prevFilter)
                                    || currentPage !== prevPage;

        const isSortUpdated = JSON.stringify(sort) !== JSON.stringify(prevSort);

        if (isProductListUpdated || isSortUpdated) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (device.isMobile && this._getIsInfiniteLoaderEnabled() && isPlp && !(isProductListUpdated || isSortUpdated)) {
            return;
        }

        if ((search !== prevSearch || isProductListUpdated)) {
            this.requestPage(this._getPageFromUrl());
        }

        if (isSortUpdated) {
            const isOnlySortUpdated = true;
            this.requestPage(this._getPageFromUrl(), false, isOnlySortUpdated);
        }
    }

    requestPage(currentPage = 1, isNext = false, isOnlySortUpdated = false) {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            // location: { pathname },
        } = this.props;
        // const { isPrefetchValueUsed } = window;

        // const isSearch = pathname.includes(Page.SEARCH);
        // const isPrefetched = isPrefetchValueUsed && !isWidget && !isSearch;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        const areEmptyFilters = filter?.categoryIds === undefined && Object.keys(filter?.customFilters || {})?.length === 0 && filter?.priceRange?.max === 0;

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort: sort ?? undefined,
                search,
                pageSize,
                currentPage,
            },
        };

        if (!areEmptyFilters) {
            options.args.filter = filter;
        }

        const infoOptions = {
            args: {
                filter,
                search,
            },
        };

        requestProductList(options);

        if (!isWidget) {
            if (!isOnlySortUpdated) {
                waitForPriorityLoad().then(
                /** @namespace Satisfly/Component/ProductList/Container/ProductListContainer/requestPage/waitForPriorityLoad/then/requestProductListInfo */
                    () => requestProductListInfo(infoOptions),
                );
            }

            // workaround for hiding category sort fields on sorting
            setTimeout(() => {
                scrollToTop();
            });
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
