/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const OBSERVER_THRESHOLD = 10;

export const INTERSECTION_RATIO = 0.5;

export const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                draggable: true,
            },
        },
        {
            breakpoint: 350,
            settings: {
                slidesToScroll: 2,
                arrows: false,
                draggable: true,
            },
        },
    ],
};

export const DEFAULT_PLACEHOLDER_COUNT = 8;
export const DEFAULT_WIDGET_PLACEHOLDER_COUNT = 5;
