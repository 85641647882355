/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Slider from 'react-slick';

import ProductListPage from 'Component/ProductListPage';
import ProductCard from 'SourceComponent/ProductCard';
import { ProductListComponent as SourceProductList } from 'SourceComponent/ProductList/ProductList.component';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import { DEFAULT_PLACEHOLDER_COUNT, DEFAULT_WIDGET_PLACEHOLDER_COUNT, sliderSettings } from './ProductList.config';

import './ProductList.style';

/**
 * List of category products
 * @class ProductList
 * @namespace Satisfly/Component/ProductList/Component */
export class ProductListComponent extends SourceProductList {
    renderPages() {
        const {
            pages,
            isVisible,
            isLoading,
            isInfiniteLoaderEnabled,
            isWidget,
        } = this.props;

        if (isLoading) {
            return this.renderPage();
        }

        const pageRenders = Object.entries(pages).map(this.renderProductPage.bind(this));

        if (isVisible && isInfiniteLoaderEnabled) { // add placeholders to the end of pages if needed
            const key = Math.max(Object.keys(pages)) + 1; // the key should match next page key

            pageRenders.push(this.renderPage({ key }));
        }

        if (isWidget) {
            return this.renderSlider();
        }

        return pageRenders;
    }

    renderList(pages) {
        const products = pages[1];

        return products.map((product, i) => (
            <ProductCard
              product={ product }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
            />
        ));
    }

    renderSlider() {
        const { pages } = this.props;

        return (
            <section block="ProductList" elem="Slider">
                <Slider { ...sliderSettings }>
                    { this.renderList(pages) }
                </Slider>
            </section>
        );
    }

    renderNoProducts() {
        return (
            <div block="ProductList">
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                    <h2>{ __('We are sorry!') }</h2>
                    <p>{ __('There were no products found matching your request.') }</p>
                    <p>{ __('Enter a different phrase and try again!') }</p>
                </div>
            </div>
        );
    }

    renderPage(props = {}) {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            mix,
            isPlp,
            isWidget,
        } = this.props;
        const {
            items = [],
            pageNumber = 0,
            selectedFilters = {},
            wrapperRef,
            key,
        } = this._processProps(props);

        return (
            <ProductListPage
              key={ key }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              items={ items }
              pageNumber={ pageNumber }
              selectedFilters={ selectedFilters }
              wrapperRef={ wrapperRef }
              isPlp={ isPlp }
              numberOfPlaceholders={ isWidget ? DEFAULT_WIDGET_PLACEHOLDER_COUNT : DEFAULT_PLACEHOLDER_COUNT }
            />
        );
    }

    render() {
        const {
            totalPages,
            isLoading,
            mix,
        } = this.props;

        if (!isLoading && totalPages === 0) {
            setLoadedFlag();

            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default ProductListComponent;
